.room-options {
  .ant-menu {
    border-right: 0;
  }
  .ant-input-number {
    width: 65px;
    margin: 0 3px;
  }
  .ant-menu-item.indent {
    margin-left: 30px;
  }
}